
export default {
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    hero: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentsMounted: 0,
    }
  },
  methods: {
    getComponent(component) {
      component = component.replace('Component', '')
      return component
    },
    getSpacing(component) {
      const compName = this.getComponent(component).toLowerCase()
      if (compName.includes('hero')) return ''
      if (compName.includes('decoration')) return '-mt-64 lg:-mb-64 lg:-mt-128' // negative of other sections
      else if (compName.includes('heading')) return 'lg:pt-128 py-32 lg:-mb-64'
      else return 'mb-64 lg:mb-0 lg:pt-64 py-32 lg:pb-128'
    },
    setMounted() {
      if (!this.hero) {
        this.componentsMounted++
        if (this.sections.length === this.componentsMounted) {
          this.$nextTick(() => this.$emit('sectionsMounted'))
        }
      } else {
        this.$nextTick(() => this.$emit('heroMounted'))
      }
    },
  },
}
